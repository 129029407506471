/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  getHospitalAdmin, getHospitalAdminError, getHospitalAdminSuccess, updateHospitalAdmin, updateHospitalAdminError, updateHospitalAdminSuccess
} from '../actions/hospitalAdminActions'

const initialState = {
  loading: false,
  hospitalAdmin: [],
  error: null
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getHospitalAdmin, (state) => {
      state.loading = true
    })
    .addCase(getHospitalAdminError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getHospitalAdminSuccess, (state, action) => {
      state.loading = false
      state.hospitalAdmin = action.payload
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .addCase(updateHospitalAdmin, (state, action) => {
      state.loading = true
    })
    .addCase(updateHospitalAdminError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateHospitalAdminSuccess, (state, action) => {
      state.loading = false
    })
})

export default caseReducer
