/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from '@reduxjs/toolkit'
import {
  getAllResponders, getAllRespondersError, getAllRespondersSuccess
} from '../actions/respondersActions'

const initialState = {
  loading: false,
  responders: [],
  error: null
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllResponders, (state) => {
      state.loading = true
    })
    .addCase(getAllRespondersError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getAllRespondersSuccess, (state, action) => {
      state.loading = false
      state.responders = action.payload
    })
})

export default caseReducer
