import { createAction } from '@reduxjs/toolkit'
import { HOSPITALS } from './types'

export const getHospital = createAction<any>(
  HOSPITALS.getHospital
)

export const getHospitalError = createAction<any>(
  HOSPITALS.getHospitalError
)

export const getHospitalSuccess = createAction<any>(
  HOSPITALS.getHospitalSuccess
)
export const updateHospital = createAction<any>(
  HOSPITALS.updateHospital
)

export const updateHospitalError = createAction<any>(
  HOSPITALS.updateHospitalError
)

export const updateHospitalSuccess = createAction<any>(
  HOSPITALS.updateHospitalSuccess
)

