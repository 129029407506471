/* eslint-disable import/no-anonymous-default-export */
import { createReducer } from '@reduxjs/toolkit'
import {
  getAllCases, getAllCasesError, getAllCasesSuccess, getCase, getCaseError, getCaseSuccess, updateCase, updateCaseError, updateCaseSuccess, getOpenCases, getOpenCasesError, getOpenCasesSuccess
} from '../actions/caseActions'

const initialState = {
  loading: false,
  cases: [],
  singleCase: [],
  error: null,
  openCases: []
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAllCases, (state) => {
      state.loading = true
    })
    .addCase(getAllCasesError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getAllCasesSuccess, (state, action) => {
      state.loading = false
      state.cases = action.payload
    })
    .addCase(getCase, (state) => {
      state.loading = true
    })
    .addCase(getCaseError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getCaseSuccess, (state, action) => {
      state.loading = false
      state.singleCase = action.payload
    })
    .addCase(updateCase, (state) => {
      state.loading = true
    })
    .addCase(updateCaseError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateCaseSuccess, (state) => {
      state.loading = false
    })
    .addCase(getOpenCases, (state) => {
      state.loading = true
    })
    .addCase(getOpenCasesError, (state) => {
      state.loading = false
    })
    .addCase(getOpenCasesSuccess, (state, action) => {
      state.loading = false
      state.openCases = action.payload
    })
})

export default caseReducer
