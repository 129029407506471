import { lazy } from 'react'

import { Route } from 'react-router-dom'
import ROUTES from './utils/constants/routes'

import ProtectedRoute from './components/RouteTypes/ProtectedRoute'
import PasswordChangeRoute from './components/RouteTypes/PasswordChangeRoute'

const Home = lazy(() => import('./pages/Home'))
const Cases = lazy(() => import('./pages/Cases'))
const Map = lazy(() => import('./pages/Map'))
const Profile = lazy(() => import('./pages/Profile'))
const Login = lazy(() => import('./pages/Login/index'))
const SetPassword = lazy(() => import('./pages/Login/SetPassword'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const ChangePassword = lazy(() => import('./pages/ForgotPassword/changePassword'))
const ResidencyInfo = lazy(() => import('./pages/Login/ResidencyInfo'))
const Verification = lazy(() => import('./pages/Login/Verification'))
const NotFound = lazy(() => import('./pages/NotFound'))

export const routes = [
  {
    component: Home,
    path: ROUTES.home,
    RouteType: ProtectedRoute
  },
  {
    component: Cases,
    path: ROUTES.cases,
    RouteType: ProtectedRoute
  },
  {
    component: Map,
    path: ROUTES.map,
    RouteType: ProtectedRoute
  },
  {
    component: Profile,
    path: ROUTES.profile,
    RouteType: ProtectedRoute
  },
  {
    component: ForgotPassword,
    path: ROUTES.forgotPassword,
    RouteType: Route
  },
  {
    component: ChangePassword,
    path: ROUTES.changePassword,
    RouteType: Route
  },
  {
    component: Login,
    path: ROUTES.login,
    RouteType: Route
  },
  {
    component: SetPassword,
    path: ROUTES.setPassword,
    RouteType: PasswordChangeRoute
  },
  {
    component: ResidencyInfo,
    path: ROUTES.residencyInfo,
    RouteType: PasswordChangeRoute
  },
  {
    component: Verification,
    path: ROUTES.phoneVerification,
    RouteType: Route
  },
  {
    component: NotFound,
    path: ROUTES.notFound,
    RouteType: Route
  }
]
