/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import url from '../../api'
import {
  getAllResponders, getAllRespondersError, getAllRespondersSuccess
} from '../actions/respondersActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* getResponderSaga(): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    const response: any = yield call(url.get, '/responders')
    let { data } = response.data
    data = data.map((item: any, index: number) => {
      if (!item.severity) {
        item.severity = 'Low'
      }
      item.key = index
      return item
    })
    yield put(getAllRespondersSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getAllRespondersError(error))
    }
    const {
      data: { message }
    } = error.response

    yield put(getAllRespondersError(message))
  }
}


function* casesSaga() {
  yield takeLeading(getAllResponders, getResponderSaga)
}

export default casesSaga
