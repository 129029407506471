import { createAction } from '@reduxjs/toolkit'
import { CASES } from './types'

export const getAllCases = createAction<any>(
  CASES.getAllCases
)

export const getAllCasesError = createAction<any>(
  CASES.getAllCasesError
)

export const getAllCasesSuccess = createAction<any>(
  CASES.getAllCasesSuccess
)

export const getCase = createAction<any>(
  CASES.getCase
)

export const getCaseError = createAction<any>(
  CASES.getCaseError
)

export const getCaseSuccess = createAction<any>(
  CASES.getCaseSuccess
)

export const updateCase = createAction<any>(
  CASES.updateCase
)

export const updateCaseError = createAction<any>(
  CASES.updateCaseError
)

export const updateCaseSuccess = createAction<any>(
  CASES.updateCaseSuccess
)

export const getOpenCases = createAction(
  CASES.getOpenCases
)
export const getCaseInfo = createAction<any>(
  CASES.getCaseInfo
)

export const getCaseInfoError = createAction<any>(
  CASES.getCaseInfoError
)

export const getCaseInfoSuccess = createAction<any>(
  CASES.getCaseInfoSuccess
)

export const getOpenCasesError = createAction(
  CASES.getOpenCasesError
)

export const getOpenCasesSuccess = createAction<any>(
  CASES.getOpenCasesSuccess
)
