
export const CASES = {
  getAllCases: 'GET_ALL_CASES',
  getAllCasesError: 'GET_ALL_CASES_ERROR',
  getAllCasesSuccess: 'GET_ALL_CASES_SUCCESS',
  getCase: 'GET_CASE',
  getCaseError: 'GET_CASE_ERROR',
  getCaseSuccess: 'GET_CASE_SUCCESS',
  updateCase: 'UPDATE_CASE',
  updateCaseError: 'UPDATE_CASE_ERROR',
  updateCaseSuccess: 'UPDATE_CASE_SUCCESS',
  getOpenCases: 'GET_OPEN_CASES',
  getOpenCasesError: 'GET_OPEN_CASES_ERROR',
  getOpenCasesSuccess: 'GET_OPEN_CASES_SUCCESS',
  getCaseInfo: 'GET_CASE',
  getCaseInfoError: 'GET_CASE_ERROR',
  getCaseInfoSuccess: 'GET_CASE_SUCCESS'
}

export const HOSPITAL_TRANSFER = {
  getAllHospitalTransfer: 'GET_HOSPITALS_TRANSFER',
  getAllHospitalTransferError: 'GET_HOSPITALS_TRANSFER_ERROR',
  getAllHospitalTransferSuccess: 'GET_HOSPITALS_TRANSFER_SUCCESS',
  getHospitalTransfer: 'GET_HOSPITAL_TRANSFER',
  getHospitalTransferError: 'GET_HOSPITAL_TRANSFER_ERROR',
  getHospitalTransferSuccess: 'GET_HOSPITAL_TRANSFER_SUCCESS',
  addHospitalTransfer: 'ADD_HOSPITAL_TRANSFER',
  addHospitalTransferError: 'ADD_HOSPITAL_TRANSFER_ERROR',
  addHospitalTransferSuccess: 'ADD_HOSPITAL_TRANSFER_SUCCESS',
  updateHospitalTransfer: 'UPDATE_HOSPITAL_TRANSFER',
  updateHospitalTransferError: 'UPDATE_HOSPITAL_TRANSFER_ERROR',
  updateHospitalTransferSuccess: 'UPDATE_HOSPITAL_TRANSFER_SUCCESS'
}

export const AUTH = {
  createUser: 'CREATE_USER',
  createUserError: 'CREATE_USER_ERROR',
  createUserSuccess: 'CREATE_USER_SUCCESS',
  signIn: 'SIGN_IN',
  signInError: 'SIGN_IN_ERROR',
  signInSuccess: 'SIGN_IN_SUCCESS',
  logout: 'LOGOUT',
  logoutError: 'LOGOUT_ERROR',
  logoutSuccess: 'LOGOUT_SUCCESS',
  resetPassword: 'FORGOT_PASSWORD',
  resetPasswordError: 'FORGOT_PASSWORD_ERROR',
  resetPasswordSuccess: 'FORGOT_PASSWORD_SUCCESS',
  completeNewPassword: 'COMPLETE_NEW_PASSWORD',
  completeNewPasswordError: 'COMPLETE_NEW_PASSWORD_ERROR',
  completeNewPasswordSuccess: 'COMPLETE_NEW_PASSWORD_SUCCESS',
  setNewPassword: 'SET_NEW_PASSWORD',
  setNewPasswordError: 'SET_NEW_PASSWORD_ERROR',
  setNewPasswordSuccess: 'SET_NEW_PASSWORD_SUCCESS',
  storeAuthToken: 'SAVE_AUTH_TOKEN',
  setAuthStatus: 'SET_AUTH_STATUS',
  mfa: 'MFA',
  mfaError: 'MFA_ERROR',
  mfaSuccess: 'MFA_SUCCESS',
  setAuthentication: 'SET_AUTHENTICATION',
  setLoading: 'SET_LOADING'
}

export const HOSPITALS = {
  getHospital: 'GET_HOSPITAL',
  getHospitalError: 'GET_HOSPITAL_ERROR',
  getHospitalSuccess: 'GET_HOSPITAL_SUCCESS',
  updateHospital: 'UPDATE_HOSPITAL',
  updateHospitalError: 'UPDATE_HOSPITAL_ERROR',
  updateHospitalSuccess: 'UPDATE_HOSPITAL_SUCCESS',
  getHospitalAdmin: 'GET_HOSPITAL_ADMIN',
  getHospitalAdminError: 'GET_HOSPITAL_ADMIN_ERROR',
  getHospitalAdminSuccess: 'GET_HOSPITAL_ADMIN_SUCCESS',
  updateHospitalAdmin: 'UPDATE_HOSPITAL_ADMIN',
  updateHospitalAdminError: 'UPDATE_HOSPITAL_ADMIN_ERROR',
  updateHospitalAdminSuccess: 'UPDATE_HOSPITAL_ADMIN_SUCCESS'
}


export const HOSPITAL_ADMIN = {
  getHospitalAdmin: 'GET_HOSPITAL_ADMIN',
  getHospitalAdminError: 'GET_HOSPITAL_ADMIN_ERROR',
  getHospitalAdminSuccess: 'GET_HOSPITAL_ADMIN_SUCCESS',
  updateHospitalAdmin: 'UPDATE_HOSPITAL_ADMIN',
  updateHospitalAdminError: 'UPDATE_HOSPITAL_ADMIN_ERROR',
  updateHospitalAdminSuccess: 'UPDATE_HOSPITAL_ADMIN_SUCCESS'
}


export const RESPONDERS = {
  getAllResponders: 'GET_ALL_RESPONDERS',
  getAllRespondersError: 'GET_ALL_RESPONDERSERROR',
  getAllRespondersSuccess: 'GET_ALL_RESPONDERSSUCCESS'
}

export const UPLOAD = {
  uploadAvatar: 'UPLOAD_AVATAR',
  uploadAvatarError: 'UPLOAD_AVATARERROR',
  uploadAvatarsSuccess: 'UPLOAD_AVATARSUCCESS'
}
