import React from 'react'
import { Spin } from 'antd'

const PageLoader = () => (
  <div className="h-screen flex justify-center items-center bg-opacity-20">
    <Spin size="large" />
  </div>
)

export default PageLoader
