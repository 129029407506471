/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from 'react'
import { nanoid } from 'nanoid'
import { Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { routes } from '../routes'
import PageLoader from '../components/common/PageLoader'
import { AuthProvider } from '../utils/contexts/authDetails'

const App: React.FC = () => {
  const [id] = useState(nanoid)

  return (
    <Suspense fallback={<PageLoader />}>
      <AuthProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />
        <Switch>
          {routes.map(({ path, component, RouteType }) => (
            <RouteType
              key={id}
              path={path}
              exact
              component={component}
            />
          ))}
        </Switch>
      </AuthProvider>
    </Suspense>
  )
}
export default App
