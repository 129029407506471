const ROUTES = {
  login: '/login',
  setPassword: '/set-password',
  residencyInfo: '/residency-info',
  phoneVerification: '/phone-verification',
  home: '/',
  cases: '/cases',
  map: '/map',
  profile: '/settings',
  secondary: '/cases/secondary/:case_id',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  changePassword: '/forgotPassword/change_password',
  notFound: '*'
}

export default ROUTES
