/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import url from '../../api'
import {
  getHospital,
  getHospitalError,
  getHospitalSuccess,
  updateHospital,
  updateHospitalError,
  updateHospitalSuccess
} from '../actions/hospitalActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* getHospitalSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { hospital_id } = action.payload
    const response: any = yield call(url.get, `v1/hospitals/${hospital_id}`)
    const { data } = response?.data

    yield put(getHospitalSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getHospitalError(error))
    }
    if (error.response?.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getHospitalError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}

// function* updateHospitalSaga(action : any) : Generator {
//   console.log('gggggggggggggg')
//   try {
//     const _authToken: any = yield call(getAuthToken)
//     yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
//     const { id, hospitalData } = action.payload
//     console.log('action.payload', action.payload)
//     const response: any = yield call(url.put, `v1/hospitals/${id}`, hospitalData)
//     console.log('responseeeeee', response)
//     const { data } = response.data
//     toast.success('Hospital updated sucessfully')
//     yield put(updateHospitalSuccess(data))
//     // yield put(getHospital({ id }))
//   } catch (error: Error | any) {
//     if (error.toString() === 'Error: Network Error') {
//       toast.error('Please check your network connection and try again')
//       yield put(updateHospitalError(error))
//     }

//     if (error.response.data) {
//       const {
//         data: { message }
//       } = error.response
//       toast.error(message)
//       yield put(updateHospitalError(message))
//     } else {
//       // eslint-disable-next-line no-console
//       console.log('error', error)
//     }
//   }
// }

interface UpdateHospitalAction {
  type: string;
  payload: {
    id: string;
    hospitalData: any;
  };
}
interface ApiResponse {
  data: any;
}

// Generator function with explicit type annotations
function* updateHospitalSaga(
  action: UpdateHospitalAction
): Generator<any, void, any> {
  try {
    const _authToken: { jwtToken: string; issue: string } = yield call(
      getAuthToken
    )
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )

    const { id, hospitalData } = action.payload
    const { hospital_id, ...filteredHospitalData } = hospitalData
    const response: ApiResponse = yield call(
      url.put,
      `v1/hospitals/${id}`,
      filteredHospitalData
    )
    const { data } = response.data

    toast.success('Hospital updated successfully')
    yield put(updateHospitalSuccess(data))
  } catch (error: unknown) {
    if (error instanceof Error) {
      if (error.message === 'Network Error') {
        // Handle network error
        toast.error('Please check your network connection and try again')
        yield put(updateHospitalError(error))
      } else if (typeof (error as any).response?.data?.message === 'string') {
        // Handle specific API errors
        const { message } = (error as any).response.data

        if (
          message
          === 'One or more parameter values were invalid: Cannot update attribute hospital_id. This attribute is part of the key'
        ) {
          toast.error('Cannot update hospital_id as it is a key attribute')
        } else {
          toast.error(message)
        }

        yield put(updateHospitalError(message))
      } else {
        console.error('Unexpected error', error)
        toast.error('An unexpected error occurred. Please try again later.')
        yield put(
          updateHospitalError(error.message || 'An unexpected error occurred.')
        )
      }
    } else {
      console.error('Non-Error exception', error)
      toast.error('An unexpected error occurred. Please try again later.')
      yield put(updateHospitalError('An unexpected error occurred.'))
    }
  }
}

function* hospitalSaga() {
  yield takeLeading(getHospital, getHospitalSaga)
  yield takeLeading(updateHospital, updateHospitalSaga)
}

export default hospitalSaga
