import { isEmpty } from 'lodash'
import React, {
  useState, createContext, useEffect, useMemo
} from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../../store/reducers'

interface IAuthContext {
    userInfo: any,
    setUserInfo: (userInfo: any) => void,
    otherDetails: any,
    setOtherDetails: (otherDetails: any) => void,
    hospitalId: string,
    setHospitalId: (hospitalId : string) => void
}

const AuthContext = createContext<IAuthContext>({
  userInfo: {},
  setUserInfo: () => true,
  otherDetails: {},
  setOtherDetails: () => true,
  hospitalId: '',
  setHospitalId: () => true
})

let _userInfo: any = {}
let _hospitalId = ''

if (localStorage.getItem('userInfo')) {
  _userInfo = localStorage.getItem('userInfo')
  _userInfo = JSON.parse(_userInfo)
}

if (localStorage.getItem('hospitalId')) {
  _hospitalId = localStorage.getItem('hospitalId') || ''
}

const AuthProvider: React.FC = (props: any) => {
  const { userDetails }: any = useSelector((state: RootState) => state.auth)

  const [userInfo, setUserInfo] = useState(_userInfo)
  const [otherDetails, setOtherDetails] = useState({})
  const [hospitalId, setHospitalId] = useState(_hospitalId)


  const userMemo = useMemo(() => userDetails,
  // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDetails])

  useEffect(() => {
    if (!isEmpty(userMemo)) {
      try {
        setUserInfo(userMemo)
        localStorage.setItem('userInfo', JSON.stringify(userMemo))
        localStorage.setItem('hospitalId', userMemo?.username)
      } catch (error) {
        localStorage.getItem(userInfo)
        localStorage.getItem(hospitalId)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMemo])

  return (
    <AuthContext.Provider
      value={
                {
                  userInfo, setUserInfo, otherDetails, setOtherDetails, hospitalId, setHospitalId
                }
            }
    >
      {props.children}
    </AuthContext.Provider>
  )
}
export { AuthContext, AuthProvider }
