import { spawn } from 'redux-saga/effects'
import casesSaga from './casesSaga'
import authSaga from './authSaga'
import hospitalSaga from './hospitalSaga'
import hospitalAdminSaga from './hospitalAdminSaga'
import uploadSaga from './uploadAvatarSaga'
import respondersSaga from './respondersSaga'

function* rootSaga() {
  yield spawn(casesSaga)
  yield spawn(authSaga)
  yield spawn(hospitalSaga)
  yield spawn(uploadSaga)
  yield spawn(hospitalAdminSaga)
  yield spawn(respondersSaga)
}

export default rootSaga
