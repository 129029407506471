import { combineReducers } from 'redux'
import cases from './casesReducer'
import auth from './authReducer'
import hospital from './hospitalReducer'
import responder from './responderReducer'
import upload from './uploadAvatar'
import hospitalAdmin from './hospitalAdminReducer'

const rootReducer = combineReducers({
  cases,
  hospital,
  auth,
  responder,
  upload,
  hospitalAdmin
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer
