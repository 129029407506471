/* eslint-disable import/no-anonymous-default-export */
import {
  put, call, takeLeading
} from 'redux-saga/effects'
import { toast } from 'react-toastify'
import url from '../../api'
import {
  uploadAvatar, uploadAvatarError, uploadAvatarSuccess
} from '../actions/updateAvatar'
import { updateHospital } from '../actions/hospitalActions'
import { updateHospitalAdmin } from '../actions/hospitalAdminActions'

function* uploadAvatarSaga(action : any) : Generator {
  try {
    const {
      entityData, mime, image, entity, name
    } = action.payload
    const response: any = yield call(url.post, '/v1/upload/image', { mime, name, image })

    const { data } = response.data
    if (entity === 'hospital') {
      const d = { ...entityData }
      const { hospital_id } = d
      d.logo_url = data.imageURL
      delete d.hospital_id
      yield put(updateHospital({ id: hospital_id, hospitalData: d }))
    } else {
      const d = { ...entityData }
      const { hospital_id } = d
      d.avatarURL = data.imageURL
      yield put(updateHospitalAdmin({ hospitalId: hospital_id, hospitalAdminData: d }))
    }

    yield put(uploadAvatarSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(uploadAvatarError(error))
    }
    // const {
    //   message
    // } = error?.response

    // yield put(uploadAvatarError(message))
  }
}

function* uploadSaga() {
  yield takeLeading(uploadAvatar, uploadAvatarSaga)
}

export default uploadSaga
