import { createAction } from '@reduxjs/toolkit'
import { HOSPITAL_ADMIN } from './types'

export const getHospitalAdmin = createAction<any>(
  HOSPITAL_ADMIN.getHospitalAdmin
)

export const getHospitalAdminError = createAction<any>(
  HOSPITAL_ADMIN.getHospitalAdminError
)

export const getHospitalAdminSuccess = createAction<any>(
  HOSPITAL_ADMIN.getHospitalAdminSuccess
)
export const updateHospitalAdmin = createAction<any>(
  HOSPITAL_ADMIN.updateHospitalAdmin
)

export const updateHospitalAdminError = createAction<any>(
  HOSPITAL_ADMIN.updateHospitalAdminError
)

export const updateHospitalAdminSuccess = createAction<any>(
  HOSPITAL_ADMIN.updateHospitalAdminSuccess
)
