import { createAction } from '@reduxjs/toolkit'
import { RESPONDERS } from './types'


export const getAllResponders = createAction(
  RESPONDERS.getAllResponders
)

export const getAllRespondersError = createAction<any>(
  RESPONDERS.getAllRespondersError
)

export const getAllRespondersSuccess = createAction<any>(
  RESPONDERS.getAllRespondersSuccess
)
