import {
  put, takeLeading, call
} from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'
import url from '../../api'
import {
  signin, signinSuccess, signinError,
  completeNewPassword, completeNewPasswordError, completeNewPasswordSuccess,
  logout, logoutError, logoutSuccess,
  setNewPasswordSuccess, setNewPasswordError,
  resetPasswordError, resetPasswordSuccess,
  setNewPassword, resetPassword,
  mfa, mfaError, mfaSuccess, storeAuthToken,
  setAuthentication, setLoading
} from '../actions/authActions'

import ROUTES from '../../utils/constants/routes'


let userRef: any
let oldPassword: string
let newPassword: string

export const getAuthToken = async () => {
  const currentSession = await Auth.currentSession()
  const idToken: any = currentSession.getIdToken()
  const { jwtToken, payload } = idToken
  const { iss } = payload
  return { jwtToken, issue: iss }
}

// const handleSignup = () => new Promise((resolve, reject) => {
//   // Auth.signUp({
//   //   username: 'leezeelola@gmail.com',
//   //   password: 'password',
//   //   attributes: {
//   //     email: 'leezeelola@gmail.com',
//   //     phone_number: '+2348093892070',
//   //     'custom:hospital': '573729f5-d968-43d3-b446-c39e41d8707e'
//   //   }
//   // })
//   // Auth.resendSignUp('leezeelola@gmail.com')
//   Auth.confirmSignUp('leezeelola@gmail.com', '646754')
//     .then((user: any) => {
//       resolve(user)
//     }).catch((error: Error) => {
//       reject(error)
//     })
// })

const handleSignIn = (email: string, password: string): any => new Promise((resolve, reject) => {
  Auth.signIn(email, password).then((user: any) => {
    resolve(user)
  }).catch((error: Error) => {
    console.log(error)
    reject(error)
  })
})

const handleCompletePassword = ({ userRef, password }: any): any => new Promise((resolve, reject) => {
  // Auth.changePassword(userRef, oldPassword, password).then((user: any) => {
  Auth.completeNewPassword(userRef, password).then((user: any) => {
    resolve(user)
  }).catch((error: Error) => {
    reject(error)
  })
})

const handleMfa = ({ userRef, code }: any): any => new Promise((resolve, reject) => {
  Auth.confirmSignIn(userRef, code, 'SMS_MFA').then((user: any) => {
    resolve(user)
  }).catch((error: Error) => {
    reject(error)
  })
})

const handleResendMfa = ({ userRef }: any): any => new Promise((resolve, reject) => {
  Auth.resendSignUp(userRef).then((user: any) => {
    resolve(user)
  }).catch((error: Error) => {
    reject(error)
  })
})

const handleForgotPassword = (email: string): any => new Promise((resolve, reject) => {
  Auth.forgotPassword(email)
    .then((data) => {
      resolve(data)
    })
    .catch((error: Error) => {
      reject(error)
    })
})

const handleForgotPasswordSubmit = (email: string, code: string, new_password: string): any => new Promise((resolve, reject) => {
  Auth.forgotPasswordSubmit(email, code, new_password)
    .then((data) => {
      resolve(data)
    })
    .catch((error: Error) => {
      reject(error)
    })
})

const handleChangeCustomAttribute = () => new Promise((resolve, reject) => {
  Auth.updateUserAttributes(userRef, {
    'custom:challenge': 'NONE'
  })
    .then((data) => {
      resolve(data)
    })
    .catch((error: Error) => {
      reject(error)
    })
})

function* signInSaga(action: any): Generator {
  const { email, password, history } = action.payload
  yield put(setLoading(true))
  try {
    const user: any = yield call(handleSignIn, email, password)
    userRef = user
    oldPassword = password
    yield put(signinSuccess(user))
    yield put(setLoading(false))

    if (userRef.challengeName === 'PASSWORD_VERIFIER') {
      yield call(handleSignIn, email, newPassword)
      history.push(ROUTES.home)
    } else if (userRef.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push(ROUTES.setPassword)
    } else {
      const _authToken: any = yield call(getAuthToken)
      yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
      yield put(setAuthentication(true))
      history.push(ROUTES.home)
    }
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(signinError())
    }

    if (error.code === 'UserNotConfirmedException') {
      toast.error('This account is not confirmed, Please check your email to confirm your account')
      return yield put(completeNewPasswordError())
    }

    const { message } = error
    toast.error(message)
    yield put(signinError())
  }
}

function* mfaSaga(action: any): Generator {
  const { code, history } = action.payload
  yield put(setLoading(true))
  try {
    const updatedUser = yield call(handleMfa, { userRef, code })
    yield put(mfaSuccess(updatedUser))
    toast.success('Code verfication successful')
    const _authToken: any = yield call(getAuthToken)
    yield put(setLoading(false))
    yield put(storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue }))
    yield put(setAuthentication(true))
    history.push(ROUTES.home)
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(completeNewPasswordError())
    }
    if (error.code === 'UserNotConfirmedException') {
      yield put(handleResendMfa({ userRef, code }))
      toast.success('A code has been sent to your phone')
      history.push(ROUTES.phoneVerification)
    }
    const { message } = error
    toast.error(message)
    yield put(mfaError())
  }
}

function* forgotPasswordSaga(action: any): Generator {
  const { email, history } = action.payload
  yield put(setLoading(true))
  try {
    const user = yield call(handleForgotPassword, email)
    yield put(resetPasswordSuccess(user))
    yield put(setLoading(false))
    toast.success('Email sent successfully')
    history.push({
      pathname: '/forgotPassword/change_password',
      state: { email }
    })
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(resetPasswordError(error))
    }

    const { message } = error
    toast.error(message)
    yield put(resetPasswordError(message))
  }
}

function* forgotPasswordSubmitSaga(action: any): Generator {
  const {
    email, history, code, password
  } = action.payload
  yield put(setLoading(false))
  try {
    const user = yield call(handleForgotPasswordSubmit, email, code, password)
    yield put(setNewPasswordSuccess(user))
    yield put(setLoading(false))
    toast.success('Password changed successfully')
    history.push(ROUTES.login)
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(setNewPasswordError(error))
    }
    const { message } = error
    toast.error(message)
    yield put(setNewPasswordError(message))
  }
}

function* newPasswordSaga(action: any): Generator {
  const { password, userInfo } = action.payload
  newPassword = password
  const details = {
    phone_number: userRef?.challengeParam?.userAttributes?.phone_number,
    email: userRef?.challengeParam?.userAttributes?.email,
    user_name: userRef?.username,
    user_id: userRef?.username
  }
  const hospitalId = userRef?.username
  yield put(setLoading(true))
  try {
    yield call(handleCompletePassword, { userRef, password })
    yield call(url.post, `/hospitals/${hospitalId}/admin`, { ...userInfo, ...details })
    yield call(handleChangeCustomAttribute)
    yield put(setLoading(false))
    yield put(completeNewPasswordSuccess())
    toast.success('Password change successful. Please login again')
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(completeNewPasswordError())
    }
    const { message } = error
    toast.error(message)
    yield put(completeNewPasswordError())
  }
}

function* signOutSaga(): Generator {
  yield put(setLoading(true))
  try {
    yield Auth.signOut()
    yield put(logoutSuccess())
    yield put(setLoading(false))
    toast.success('Logout successful. please login again to access the hospital application')
  } catch (error: Error | any) {
    yield put(setLoading(false))
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(logoutError())
    }
    const { message } = error
    toast.error(message)
    yield put(logoutError())
  }
}

function* authSaga() {
  yield takeLeading(signin, signInSaga)
  yield takeLeading(completeNewPassword, newPasswordSaga)
  yield takeLeading(mfa, mfaSaga)
  yield takeLeading(setNewPassword, forgotPasswordSubmitSaga)
  yield takeLeading(resetPassword, forgotPasswordSaga)
  yield takeLeading(logout, signOutSaga)
}

export default authSaga
