import axios from 'axios'
import appStore from '../store'

import config from '../utils/config'

const url = axios.create({
  baseURL: config.baseUrl,
  timeout: 120000
})

let signal = axios.CancelToken.source()

url.interceptors.request.use(
  (request) => {
    const { token, issuer } = appStore.store.getState().auth
    request.headers.Authorization = `Bearer ${token}`
    request.headers.common['X-Cognito-Issuer'] = issuer
    return request
  }
)

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
export const areRequestsCanceled = (err: any) => err && axios.isCancel(err)

/**
 * cancels request
 * @function
 */
export const cancelRequests = () => {
  signal.cancel({ response: { data: { code: 499, message: 'Requests canceled', status: 'CANCELLED' }, isCanceled: true } } as any)
  signal = axios.CancelToken.source()
  return signal
}

export default url
