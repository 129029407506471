/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit'
import {
  getHospital, getHospitalError, getHospitalSuccess, updateHospital, updateHospitalError, updateHospitalSuccess
} from '../actions/hospitalActions'

const initialState = {
  loading: false,
  hospitals: [],
  singleHospital: [],
  error: null
}

const caseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getHospital, (state) => {
      state.loading = true
    })
    .addCase(getHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(getHospitalSuccess, (state, action) => {
      state.loading = false
      state.singleHospital = action.payload
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .addCase(updateHospital, (state, action) => {
      state.loading = true
    })
    .addCase(updateHospitalError, (state, action) => {
      state.loading = false
      state.error = action.payload
    })
    .addCase(updateHospitalSuccess, (state, action) => {
      state.loading = false
    })
})

export default caseReducer
